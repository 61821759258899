<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <!--
          <div class="form-group">
            <label>Compañía (*):</label>
            <input type="text" class="form-control" v-model="item.cmp_id" disabled="true">
          </div>
        -->
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Tipo de tabla (*):</label>
              <select v-model="item.tta_id" class="form-control" required autofocus v-bind:disabled="!add">
                <option v-for="tpotabla in tpotablas" v-bind:value="tpotabla._id">
                  {{ tpotabla.tta_nmbre }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label>Versión (*):</label>
              <input type="number" class="form-control" v-model="item.tav_nmro" required v-bind:disabled="!add">
            </div>
          </div>
          <div class="form-group">
            <label>Dependencia (*):</label>
            <select v-model="item.dep_id" class="form-control" required autofocus v-bind:disabled="!add">
              <option v-for="dependencia in dependencias" v-bind:value="dependencia._id">
                {{ dependencia.dep_cdgo + ' - ' + dependencia.dep_nmbre }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Serie (*):</label>
            <select v-model="item.ser_id" class="form-control" required autofocus v-bind:disabled="!add">
              <option v-for="serie in series" v-bind:value="serie._id">
                {{ serie.ser_cdgo + ' - ' + serie.ser_nmbre }}
              </option>
            </select>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Años en Gestión (*):</label>
              <input type="number" class="form-control" v-model="item.tab_tpg" required>
            </div>
            <div class="form-group col-md-6">
              <label>Años en Central (*):</label>
              <input type="number" class="form-control" v-model="item.tab_tpc" required>
            </div>
          </div>
          <div class="form-row text-center">
            <div class="form-group col-md-3">
              <input type="checkbox" class="form-check-input" id="tab_cnsrvcn" v-model="item.tab_cnsrvcn">
              <label for="tab_cnsrvcn">Conservación:</label>
            </div>
            <div class="form-group col-md-3">
              <input type="checkbox" class="form-check-input" id="tab_elmncn" v-model="item.tab_elmncn">
              <label for="tab_elmncn">Eliminación:</label>
            </div>
            <div class="form-group col-md-3">
              <input type="checkbox" class="form-check-input" id="tab_dgtlzcn" v-model="item.tab_dgtlzcn">
              <label for="tab_dgtlzcn">Digitalización:</label>
            </div>
            <div class="form-group col-md-3">
              <input type="checkbox" class="form-check-input" id="tab_slccn" v-model="item.tab_slccn">
              <label for="tab_slccn">Selección:</label>
            </div>
          </div>
          <div class="form-group">
            <label>Procedimiento (*):</label>
            <input type="text" class="form-control" v-model="item.tab_prcdmnto" required>
          </div>
          <div class="form-row text-center">
            <div class="form-group col-md-12">
              <input type="checkbox" class="form-check-input" id="tab_activa" v-model="item.tab_activa">
              <label for="tab_activa">Activa:</label>
            </div>
            <!--
            <div class="form-group col-md-6">
              <input type="checkbox" class="form-check-input" id="tab_cph" v-model="item.tab_cph">
              <label for="tab_cph">Cambiar primera hoja:</label>
            </div>
            -->
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';

  export default {
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: '',
        tpotablas: [],
        dependencias: [],
        series: []
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {
      this.fetchTpotabla();
      this.fetchDependencia();
      this.fetchSerie();

      this.add = this.$route.params.add;
      if (this.add){
        this.title = 'Crear una Tabla';
        this.buttontext = 'Agregar Tabla';
        this.item.tab_activa = true;
      } else {
        this.title = 'Editar una Tabla';
        this.buttontext = 'Actualizar Tabla';
        this.getItem(this.$route.params.id);
      }
    },
    methods: {
      setClose(close){
        this.close = close;
      },
      fetchTpotabla()
      {
        let uri = '/tpotablas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpotablas = response.data;
        });
      },
      fetchDependencia(){
        let uri = '/deps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            if (a.dep_cdgo > b.dep_cdgo) { return 1; };
            if (a.dep_cdgo < b.dep_cdgo) { return -1; };
            return 0;
          });
          this.dependencias = list;
        });
      },
      fetchSerie(){
        let uri = '/series/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            if (a.ser_cdgo > b.ser_cdgo) { return 1; };
            if (a.ser_cdgo < b.ser_cdgo) { return -1; };
            return 0;
          });
          this.series = list;
        });
      },
      saveItem(){
        if (this.add){
          this.item.cmp_id = String(this.$store.state.company);
          let uri = '/tablas/add';
          //alert(this.item);
          this.axios.post(uri, this.item)
          .then(response => {
            this.$router.replace({ name: 'DisplayTabla' });
            this.message = 'Registro guardado';
          })
          .catch(err => {
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/tablas/update/' + this.item._id;
          this.axios.post(uri, this.item).then((response) => {
            if (this.close){
              this.$router.push({name: 'DisplayTabla'});
            }
            this.message = 'Registro guardado';
          });
        }
      },
      getItem(id)
      {
        let uri = '/tablas/edit/' + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;
        });
      },
      cancel(){
        this.$router.replace({name: 'DisplayTabla'});
      }
    }
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
